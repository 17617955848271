import React, { useCallback, useEffect, useState } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';

import { selectUserSpaceId } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import usePageLogic from 'hooks/common/usePageLogic';
import lang from 'lang';
import routes from 'routes';

import PageHeader from 'components/common/layout/PageHeader';
import Loading from 'components/common/Loading';
import ImportStudentsModal from 'components/students/ImportStudentsModal';
import StudentActions from 'components/students/StudentActions';
import StudentFilters from 'components/students/StudentFilters';
import StudentsTable from 'components/students/StudentsTable';

const PAGE_SIZE = 50;

const Students = () => {
  const { loaded } = usePageLogic(lang.students.title, true);
  const [getSchoolUsersRequest, loading, abortRequest] = useApi(api.getSchoolUsers);
  const toast = useToast();
  const schoolId = useSelector(selectUserSpaceId);

  const [importStudentsModal, setImportStudentsModal] = useState(false);
  const [students, setStudents] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [userFilter, setUserFilter] = useState('');
  const [classesFilter, setClassesFilter] = useState([]);

  const fetchStudents = useCallback((page) => {
    if (!schoolId) {
      return;
    }

    const request = {
      params: {
        schoolId: schoolId,
      },
      query: {
        page: page,
        pageSize: PAGE_SIZE,
        userSearch: userFilter,
        classIds: classesFilter.map(class_ => class_.value),
        role: 'student',
        relations: ['classes'],
      },
    };

    getSchoolUsersRequest(request, ({ data }) => {
      if (data.status === 0) {
        page !== 1
          ? setStudents(students => [...students, ...data.users])
          : setStudents(data.users);
        setHasMore(data.users.length === PAGE_SIZE);
        setPage(page + 1);
        loaded();
        return;
      }

      if (data.status !== ERROR && data.status !== CANCELED) {
        toast.error(lang.oops);
        loaded();
      }
    });
  }, [schoolId, userFilter, classesFilter, getSchoolUsersRequest, loaded, toast]);

  const resetStudents = useCallback(() => {
    setPage(1);
    setStudents([]);
    fetchStudents(1);
  }, [fetchStudents]);

  useEffect(() => {
    resetStudents();

    return () => {
      abortRequest();
    };
  }, [resetStudents, abortRequest]);

  const path = [
    {
      type: 'link',
      text: lang.students.title,
      to: routes.students.ref(),
    },
  ];

  const actions = [
    {
      label: lang.students.importStudents.header,
      onClick: () => setImportStudentsModal(true),
    },
  ];

  return (
    <div>
      <PageHeader
        path={path}
        actions={actions}
      />
      <ImportStudentsModal
        open={importStudentsModal}
        close={() => setImportStudentsModal(false)}
        currentStudents={students}
        resetStudents={resetStudents}
      />
      <StudentFilters
        userFilter={userFilter}
        setUserFilter={setUserFilter}
        classesFilter={classesFilter}
        setClassesFilter={setClassesFilter}
      />
      <StudentActions
        students={students}
        setStudents={setStudents}
        resetStudents={resetStudents}
      />
      <InfiniteScroll
        dataLength={students.length}
        next={() => fetchStudents(page)}
        hasMore={hasMore}
        loader={<Loading active={loading} />}
      >
        <StudentsTable
          students={students}
          resetStudents={resetStudents}
          setStudents={setStudents}
        />
      </InfiniteScroll>
    </div>
  );
};

export default Students;
